import './Pipeline.css'

function Pipeline() {
    return (
        <div className="pipeline-container">

            <div className="container">

                <br /><br />
                <h1 className='blue-title'>Les étapes d'un projet</h1>
                <br /><br />

                <div className="columns">

                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <h1 className='number-title'>1</h1>
                                <h1 className='product-title'><i class="fa-solid fa-handshake"></i> Prise de contact</h1>
                                Vous nous contactez par email pour nous expliquer votre projet et vos besoins. Nous vous proposons un devis et une fois tous les détails réglés, nous précisons le cahier des charges ensemble et démarrons le développement de l'application.
                                <br /><br />
                            </div>
                        </article>
                    </div>

                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <h1 className='number-title'>2</h1>
                                <h1 className='product-title'><i class="fa-solid fa-code"></i> Développement</h1>
                                Au cours du développement, nous vous tenons informé de l'avancée du projet et vous demandons votre avis sur les différentes étapes. Nous vous proposons des maquettes et des prototypes pour que vous puissiez visualiser le rendu final.
                                <br /><br />
                            </div>
                        </article>
                    </div>

                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <h1 className='number-title'>3</h1>
                                <h1 className='product-title'><i class="fa-solid fa-server"></i> Hébergement</h1>
                                Une fois le développement terminé, nous nous occupons de l'hébergement de votre application. Les frais d'hébergement sont inclus dans le devis initial et vous n'avez rien à payer en plus. Il est possible d'ajouter une option si vous souhaitez que nous nous occupions du nom de domaine.
                            </div>
                        </article>
                    </div>

                </div>

                <div className="columns">

                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <h1 className='number-title'>4</h1>
                                <h1 className='product-title'><i class="fa-solid fa-plane-departure"></i> Déploiement</h1>
                                L'application est d'abord déployée en version de test pour que vous puissiez nous faire un retour. Une fois validée, nous la déploierons en production pour la rendre accessible au public.
                                <br /><br />
                            </div>
                        </article>
                    </div>

                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <h1 className='number-title'>5</h1>
                                <h1 className='product-title'><i class="fa-solid fa-arrow-pointer"></i> Référencement</h1>
                                Nous nous occupons du référencement de votre application. L'objectif est d'optimiser le positionnement de votre site web ou application dans les résultats des moteurs de recherche, pour augmenter sa visibilité et attirer plus de visiteurs.
                            </div>
                        </article>
                    </div>

                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <h1 className='number-title'>6</h1>
                                <h1 className='product-title'><i class="fa-solid fa-toolbox"></i> Maintenance</h1>
                                Une fois l'application déployée, nous vous proposons une maintenance qui dure sur toute la durée du contrat (jusqu'à résiliation). Nous vous garantissons une assistance technique, des mises à jour de sécurité et des correctifs en cas de bugs.
                            </div>
                        </article>
                    </div>

                </div>
                
            </div>
            <br /><br />
        </div>
    )
}

export default Pipeline;