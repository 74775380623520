import './Footer.css'

function Footer() {
    return (
        <div className='footer-container'>
            <footer class="footer">
                <div class="content has-text-centered">
                    <p>
                        <span>Team Tussell - All rights reserved - 2024</span>
                    </p>
                </div>
            </footer>
        </div>
    )
}

export default Footer;