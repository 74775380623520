import './Integrations.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import planity from './planity.png'
import osm from './osm.png'
import calendly from './calendly.png'
import booking from './booking.png'
import stripe from './stripe.png'
import paypal from './paypal.png'
import zenchef from './zenchef.png'
import thefork from './thefork.png'
import tripadvisor from './tripadvisor.png'
import doinsport from './doinsport.png'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const sliderImageUrl = [

    {
        url:
            planity
    },

    {
        url:
            stripe
    },

    {
        url:
            osm
    },

    {
        url:
            booking
    },

    {
        url:
            tripadvisor
    },

    {
        url:
            thefork
    },

    {
        url:
            calendly
    },

    {
        url:
            paypal
    },

    {
        url:
            doinsport
    },

    {
        url:
            zenchef
    },
];

function Integrations() {
    return (
        <div className='integrations-container'>
            
            <div className="container">

                <br /><br />
                <h1 className='orange-title'>Nos intégrations</h1>
                <br /><br />
                <h2 className='orange-subtitle'>L'intégration d'applications tierces permet à votre site web ou application de profiter de fonctionnalités liées à d'autres services. Les exemples les plus courants sont les systèmes de réservation, de paiement, de navigation... La liste est non exhaustive.</h2>

                <div className='carousel-container'>
                    <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        infinite={true}
                        partialVisible={false}
                        dotListClass="custom-dot-list-style"
                    >
                        {sliderImageUrl.map((imageUrl, index) => {
                        return (
                            <div className="slider" key={index}>
                            <img src={imageUrl.url} alt="Intégration" />
                            </div>
                        );
                        })}
                    </Carousel>
                </div>
                
            </div>


            <br /><br />
        </div>
    )
}

export default Integrations;