import './Navbar.css'
import logo from '../../logo.svg'

function Navbar() {
    return (
        <nav className="navbar is-transparent">
        <div className="navbar-brand">
            <a className="navbar-item" href="https://dev.teamtussell.com">
                <img className="navbar-logo" src={logo} alt="Team Tussell" height="50px" width="50px" />
                <h1 className="navbar-name">Team Tussell</h1>
            </a>
        </div>
    
        <div className="navbar-menu">
            <div className="navbar-end">
                <div className="navbar-item">
                    <div className="field is-grouped">
                        <p className="control">
                            <a className="button is-primary"
                                href="mailto:mathis@grisel.eu">
                                <span className="icon">
                                    <i className="fa-solid fa-envelope"></i>
                                </span>
                                <span>Nous contacter</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    )
}

export default Navbar;