import { useEffect } from 'react';
import './Video.css'
import video from './video.mp4'

function Video() {
    useEffect(() => {
        const typedTextSpan = document.querySelector(".typed-text");
        const descSpan = document.querySelector("#desc-text");
        const cursorSpan = document.querySelector(".cursor");

        const textArray = ["Sites vitrines", "Boutiques", "Applications mobiles", "Référencement"];
        const descArray = ["Nous développons des sites vitrines pour présenter vos produits.", "Nous créons des boutiques en ligne pour vendre vos produits et services.", "Nous créons des applications multisupports pour s'adapter à un public divers.", "Nous optimisons le référencement de votre site pour qu'il soit le mieux classé sur Google."];
        const typingDelay = 50;
        const erasingDelay = 50;
        const newTextDelay = 5000; // Delay between current and next text
        let textArrayIndex = 0;
        let charIndex = 0;

        function type() {
            if (charIndex < textArray[textArrayIndex].length) {
                if (!cursorSpan.classList.contains("typing"))
                cursorSpan.classList.add("typing");
                typedTextSpan.textContent = textArray[textArrayIndex].substring(0, charIndex + 1);
                charIndex++;
                descSpan.textContent = descArray[textArrayIndex];
                setTimeout(type, typingDelay);
            } else {
                cursorSpan.classList.remove("typing");
                setTimeout(erase, newTextDelay);
            }
        }

        function erase() {
            if (charIndex > 0) {
                if (!cursorSpan.classList.contains("typing"))
                cursorSpan.classList.add("typing");
                typedTextSpan.textContent = textArray[textArrayIndex].substring(
                0,
                charIndex - 1
                );
                charIndex--;
                setTimeout(erase, erasingDelay);
            } else {
                cursorSpan.classList.remove("typing");
                textArrayIndex++;
                if (textArrayIndex >= textArray.length) textArrayIndex = 0;
                    setTimeout(type, typingDelay);
                }
            }
            
        if (textArray.length) setTimeout(type, 0);
    }, []);

    return (
        <div className="video-container">
            <video preload="auto" autoPlay muted loop playsInline id="video">
                <source src={video} type="video/mp4"/>
            </video>
            <div className="video-overlay">
                <div className="video-overlay-content">
                    <p><span className="typed-text"></span><span className="cursor">&nbsp;</span></p>
                    <p><span id="desc-text"></span></p>
                </div>
            </div>
        </div>
    )
}

export default Video;
