import './Products.css'
import picture from './picture.png'
import palette from './palette.png'
import index from './index.png'
import mouse from './mouse.png'
import computer from './computer.png'
import iphone from './iphone.png'
import calendar from './calendar.png'
import basket from './basket.png'

function Products() {
    return (
        <div className="products-container">
            <div className="container products">

                <br /><br />
                <h1 className='blue-title'>Nos produits</h1>
                <br /><br />

                <div className="columns">
                    <div className="column">
                        <article className="message">
                            <div className="message-body">
                                <div className='columns'>
                                    <div className='column is-one-quarter centered-images'>
                                        <img src={picture} alt='Sites vitrines' id='product-picture' />
                                        <img src={palette} alt='Palette de couleurs' id='product-palette' />
                                    </div>
                                    <div className='column is-three-quarters'>
                                        <h1 className='product-title'>Site vitrine</h1>
                                        Présentez votre commerce et ses produits au travers d’une interface moderne, personnalisée selon vos directives et parfaitement adaptée à tous les appareils.
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="column second-column">
                        <article className="message">
                            <div className="message-body">
                                <div className='columns'>
                                    <div className='column is-one-quarter centered-images'>
                                        <img src={calendar} alt='Calendrier' id='product-calendar' />
                                        <img src={basket} alt='Panier' id='product-basket' />
                                    </div>
                                    <div className='column is-three-quarters'>
                                        <h1 className='product-title'>Réservation et boutique</h1>
                                        Permettez à vos clients de réserver vos services et de commander vos produits en quelques clics, grâce à l'intégration de services de réservation et de paiement tiers.
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="columns">
                    <div className="column other-columns">
                        <article className="message">
                            <div className="message-body">
                                <div className='columns'>
                                    <div className='column is-one-quarter centered-images'>
                                        <img src={computer} alt='Ordinateur' id='product-computer' />
                                        <img src={iphone} alt='iPhone' id='product-iphone' />
                                    </div>
                                    <div className='column is-three-quarters'>
                                        <h1 className='product-title'>Application multi-supports</h1>
                                        Déclinez vos applications web en applications natives pour tous les supports, afin d'offrir une expérience utilisateur optimale à vos clients peu importe l’appareil utilisé.
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="column other-columns">
                        <article className="message">
                            <div className="message-body">
                                <div className='columns'>
                                    <div className='column is-one-quarter centered-images'>
                                        <img src={index} alt='Indexeur' id='product-index' />
                                        <img src={mouse} alt='Souris' id='product-mouse' />
                                    </div>
                                    <div className='column is-three-quarters'>
                                        <h1 className='product-title'>Référencement</h1>
                                        Placez votre business en tête des résultats de recherche sur tous les indexeurs de sites web, dont le plus utilisé est Google, et augmentez votre visibilité sur internet.
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

            </div>

            <br /><br />
        </div>
    )
}

export default Products;
