import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from './components/navbar/Navbar';
import Video from './components/video/Video';
import reportWebVitals from './reportWebVitals';
import Products from './components/products/Products';
import Integrations from './components/integrations/Integrations';
import Pipeline from './components/pipeline/Pipeline';
import Footer from './components/footer/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navbar />
    <Video />
    <Products />
    <Integrations />
    <Pipeline />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
